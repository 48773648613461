@import '../theme-bootstrap';

.mantle-play-icon {
  position: relative;
  cursor: pointer;
  &::before {
    content: ' ';
    display: block;
    background: url(#{$base-theme-path}/img/icons/src/play.svg) no-repeat;
    background-position: center center;
    width: 25px;
    height: 25px;
    margin: 0 auto;
    top: 50%;
    #{$ldirection}: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
